import { useMemo } from 'react';

import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { IActivityDefinition } from '~/types/interfaces/activity';
import { getApplicationAvailability } from '~/utils/activityAvailability/get-application-availability';
import { AvailabilityResult } from '~/utils/activityAvailability/types';

export const useApplicationAvailabilityController = (props: {
  activityDefinition?: IActivityDefinition;
  activity?: IUserActivity;
  selectedTeamId?: string;
  teamEventSize?: number;
}) => {
  const { activityDefinition, activity, selectedTeamId, teamEventSize } = props;

  const applicationAvailability: AvailabilityResult = useMemo(() => {
    return getApplicationAvailability(
      activityDefinition,
      activity,
      selectedTeamId,
      teamEventSize,
    );
  }, [activityDefinition, activity, selectedTeamId, teamEventSize]);

  return { applicationAvailability };
};
