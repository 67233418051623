import { HStack, IconButton, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useActivityTeamEventCounterController } from './controller';
import { ActivityTeamEventCounterProps } from './types';

export const ActivityTeamEventCounter = ({
  teamMaxSize,
  teamMinSize,
}: ActivityTeamEventCounterProps) => {
  const {
    teamEventSize,
    handleDecrement,
    handleIncrement,
    isTeamEventSizeModified,
  } = useActivityTeamEventCounterController({
    teamMaxSize,
    teamMinSize,
  });

  return (
    <HStack
      alignItems="center"
      justifyContent="flex-end"
      marginBottom={0}
      marginTop="auto"
      space={isTeamEventSizeModified ? 6 : 2}
      width="100%"
    >
      <IconButton
        borderWidth="1"
        borderColor={colors.gray[200]}
        borderRadius="8px"
        icon={<Icon size="14px" icon="minus" />}
        isDisabled={teamEventSize === teamMinSize || !isTeamEventSizeModified}
        onPress={handleDecrement}
        _focus={{
          borderColor: colors.gray[200],
        }}
        _hover={{
          backgroundColor: colors.gray[200],
        }}
      />
      {isTeamEventSizeModified ? (
        <Text fontSize="2xl">{teamEventSize}</Text>
      ) : (
        <Stack>
          <Text color="gray.500" fontSize="sm" textAlign="center" width="68px">
            Select
          </Text>
          <Text color="gray.500" fontSize="sm" textAlign="center" width="68px">
            team size
          </Text>
        </Stack>
      )}
      <IconButton
        borderWidth="1"
        borderColor={colors.gray[200]}
        borderRadius="8px"
        icon={<Icon size="14px" icon="plus" />}
        isDisabled={teamEventSize === teamMaxSize}
        onPress={handleIncrement}
        _focus={{
          borderColor: colors.gray[200],
        }}
        _hover={{
          backgroundColor: colors.gray[200],
        }}
      />
    </HStack>
  );
};
