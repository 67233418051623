import {
  isBefore as isDateBefore,
  isEqual as isDateEqual,
  isValid as isValidDate,
  startOfToday,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import { ActivitySemanticType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { BlockedDate } from '~/components/CalendarInline/types';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import {
  ActivityViewFilters,
  useActivityViewStore,
} from '~/pages/Authenticated/v2/ActivityView/store';
import useTeamApplicationController from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/components/EventApplicationPanel/team-application-controller';
import { ApplicationActionStyleMapByType } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/constants/application-style-map';
import { useApplicationAvailabilityController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-availability-controller';
import { useActivityApplicationController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityApplication/controllers/application-controller';
import { ExecutionPlatform } from '~/store/slices/inApp/types';
import { getActivitySpotsAvailable } from '~/utils/activity/activity-getters';
import { getLocationFromAddress, isLocationEqual } from '~/utils/getLocation';
import { generateUrlWithQueryParams } from '~/utils/transformURL';
import { useTeamEventStore } from '~/zustand/store/TeamEvent';

const today = startOfToday();
export const isDateBlocked = (dueDate?: Date) =>
  dueDate && isValidDate(dueDate) && isDateBefore(dueDate, today);

export const useEventActivityApplicationController = () => {
  // -- Providers
  const { goToRoute } = useRouter();
  const { platform } = useAppSelector((state) => state.inApp);
  const filters = useActivityViewStore(
    (state) => state.filters,
  ) as ActivityViewFilters;

  const { setFilters } = useActivityViewStore((state) => state);

  const {
    activityDefinition,
    activities,
    activityType,
    selectedActivity,
    onExternalApplication,
    title,
    headerStyle,
  } = useActivityApplicationController();

  const teamController = useTeamApplicationController();

  const { teamEventSize } = useTeamEventStore();

  const { applicationAvailability } = useApplicationAvailabilityController({
    activityDefinition,
    activity: selectedActivity,
    selectedTeamId: teamController.selectedTeam?._id,
    teamEventSize,
  });

  const [searchParams, setSearch] = useSearchParams();
  const navigate = useNavigate();

  // -- States
  const [showLocationDrawer, setShowLocationDrawer] = useState(false);
  const [showCalendarDrawer, setShowCalendarDrawer] = useState(false);
  const [availableLocations, setAvailableLocations] = useState<ILocation[]>([]);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [blockedDates, setBlockedDates] = useState<BlockedDate[]>([]);

  // -- Hooks
  useEffect(() => {
    if (activities.length == 0) return;

    const isFromHome =
      activityDefinition?.locationOption === LocationOptionsEnum.FromHome;

    const availableLocations: ILocation[] = [];
    const blockedDates: BlockedDate[] = [];
    const availableDates: Date[] = [];

    for (const activity of activities) {
      const activityLocation = getLocationFromAddress(activity.address);
      const activityDate = new Date(activity.startDate);

      const isTeamEvent =
        activityDefinition?.eventApplicationType === EEventApplicationType.Team;

      // Activities with application are blocked
      if (activity.userApplication && !isTeamEvent) {
        blockedDates.push({ date: activityDate, message: 'Already booked' });
      } else {
        const dueDate = activity?.dueDate && new Date(activity?.dueDate);
        const endDate = activity?.endDate && new Date(activity?.endDate);
        if (isDateBlocked(dueDate) || isDateBlocked(endDate)) {
          blockedDates.push({ date: activityDate, message: 'Closed' });
        }
      }

      // Ensure only dates related to selected location
      if (
        isFromHome ||
        (filters.location &&
          isLocationEqual(filters.location, activityLocation))
      ) {
        availableDates.push(activityDate);
      }

      // Prevent current selected location from list
      const locationIsSelected =
        filters?.location &&
        isLocationEqual(activityLocation, filters!.location);
      if (locationIsSelected) continue;

      // Ensure only unique locations will be shown
      const sameLocation = availableLocations.some((someLocation) =>
        isLocationEqual(someLocation, activityLocation),
      );
      if (!sameLocation) availableLocations.push(activityLocation);
    }

    setAvailableDates(availableDates);
    setAvailableLocations(availableLocations);
    setBlockedDates(blockedDates);
  }, [activityDefinition, activities, filters]);

  // -- Handlers
  const handleApplication = () => {
    if (!selectedActivity) return;

    if (selectedActivity?.externalApplyLink) {
      onExternalApplication();
      return;
    }

    const app = searchParams.get('app') || '';

    if (activityType == ActivitySemanticType.TeamEvent) {
      // const selectedTeam = teamController.selectedTeam;
      // if (!selectedTeam) return;

      const baseUrl = PAGES.TeamEventApplication.replace(
        ':eventId',
        selectedActivity._id,
      );

      const route = generateUrlWithQueryParams(baseUrl, { app });
      navigate(route);
      return;
    }

    const baseUrl = PAGES.EventApplication.replace(
      ':eventId',
      selectedActivity._id,
    );
    const route = generateUrlWithQueryParams(baseUrl, { app });
    navigate(route);
  };

  const handleNewLocation = (newLocation: ILocation | null) => {
    setFilters({ location: newLocation, startDate: null });
    if (!newLocation) return;
    resolveActivity({ location: newLocation });
  };

  const handleNewDate = (newDate: Date) => {
    closeMobileCalendarDrawer();
    setFilters({ location: filters.location, startDate: newDate });
    resolveActivity({
      startDate: newDate,
      location: filters.location || undefined,
    });
  };

  const resolveActivity = (params: {
    startDate?: Date;
    location?: ILocation;
  }) => {
    const { location, startDate } = params;
    if (!location && !startDate) return;

    for (const activity of activities) {
      const activityLocation = getLocationFromAddress(activity.address);
      const activityDate = new Date(activity.startDate);

      const locationMatches =
        location && isLocationEqual(location, activityLocation);
      const dateMatches = startDate && isDateEqual(startDate, activityDate);

      if (
        (!startDate && locationMatches) ||
        (!location && dateMatches) ||
        (locationMatches && dateMatches)
      ) {
        const params = new URLSearchParams(searchParams);
        params.set('selected', activity._id);
        setSearch(params.toString());
        break;
      }
    }
  };

  const handleCreateNewTeam = () => {
    if (platform === ExecutionPlatform.WEBVIEW) {
      goToRoute(PAGES.CreateTeam);
      return;
    }
    window.open(PAGES.CreateTeam);
  };

  const openMobileCalendarDrawer = () => {
    setShowCalendarDrawer(true);
  };

  const closeMobileCalendarDrawer = () => {
    setShowCalendarDrawer(false);
  };

  const openMobileLocationDrawer = () => {
    setShowLocationDrawer(true);
  };

  const closeMobileLocationDrawer = () => {
    setShowLocationDrawer(false);
  };

  // Computed
  const styleMap = activityType
    ? ApplicationActionStyleMapByType.get(activityType)
    : undefined;

  const spotsAvailable = getActivitySpotsAvailable(selectedActivity);

  // --Render statements
  const isFromHome =
    activityDefinition?.locationOption === LocationOptionsEnum.FromHome;

  return {
    ...teamController,
    spotsAvailable,
    isFromHome,
    availableLocations,
    availableDates,
    blockedDates,
    handleNewDate,
    handleNewLocation,
    handleApplication,
    applicationAvailability,
    styleMap,
    activityType,
    filters,
    selectedActivity,
    showCalendarDrawer,
    openMobileCalendarDrawer,
    closeMobileCalendarDrawer,
    showLocationDrawer,
    openMobileLocationDrawer,
    closeMobileLocationDrawer,
    title,
    headerStyle,
    handleCreateNewTeam,
  };
};
