import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import EcosystemService from '~/services/resources/ecosystem';
import { UserProfileService } from '~/services/resources/user-profile';
import { authSliceActions } from '~/store/slices/auth';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';

export const useChangeEcosystem = () => {
  // states
  const { user } = useAppSelector((state) => state.auth);

  // hooks
  const { handleUpdateUserPermissions } = usePermissions();
  const dispatch = useAppDispatch();

  const handleChangeEcosystem = async (ecosystemId: string) => {
    if (!user?._id) return;
    const { data: ecosystem } = await EcosystemService.findOne({
      id: ecosystemId,
    });
    if (ecosystem) {
      const { data: userProfiles } = await UserProfileService.getProfilesByUser(
        user._id,
      );

      const userProfile = userProfiles?.find(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (u) => u.ecosystem._id === ecosystem._id,
      );

      if (userProfile) {
        dispatch(ecosystemSliceActions.resetEcosystem());
        dispatch(
          ecosystemSliceActions.selectEcosystem({
            ecosystem,
            isDefaultEcosystem: false,
          }),
        );
        dispatch(
          authSliceActions.update({
            selectedUserProfile: {
              ...userProfile,
              ecosystem: ecosystem._id,
            },
            redirectToSwitchProfile: false,
          }),
        );

        await handleUpdateUserPermissions(userProfile._id);
      }
    }
  };

  return { handleChangeEcosystem };
};
