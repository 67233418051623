import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedResponse } from 'ui/types/interfaces';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useActivityViewStore } from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { mergeAppliedActivities } from '~/pages/Authenticated/v2/ActivityView/utils';
import { logAvailabilityController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/logAvailabilityController';
import { parseUserActivitiesToMap } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/parseUserActivitiesToMap';
import { ActivityLogPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/types';
import { LogStyleMapByType } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/constants/log-style-map';
import { ITeam } from '~/types/interfaces/team';

export const useActivityLogPanelController = (props: ActivityLogPanelProps) => {
  const { appliedActivities, teamAppliedActivities } = props;

  const navigate = useNavigate();

  const { activityDefinition, activityType } = useActivityViewStore(
    (state) => state,
  );

  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  // -- States
  const [appliedActivitiesMap] = useState<Map<string, IUserActivity>>(
    parseUserActivitiesToMap(props.appliedActivities),
  );
  const [activityIndex, setActivityIndex] = useState(0);

  const _appliedActivity = useMemo(() => {
    return teamAppliedActivities?.[0] || appliedActivities?.[0] || null;
  }, [teamAppliedActivities, appliedActivities]);

  const [selectedAppliedActivity, setSelectedAppliedActivity] =
    useState<IUserActivity | null>(_appliedActivity);

  const mergedAppliedActivities = mergeAppliedActivities(
    teamAppliedActivities,
    appliedActivities,
  );

  const _appliedActivities = Array.from(mergedAppliedActivities.values());

  const selectAppliedActivity = (activityId: string) => {
    const activity = appliedActivitiesMap.get(activityId);
    setSelectedAppliedActivity(activity || null);
  };

  const selectAppliedActivityByIndex = (index: number) => {
    const activity = teamAppliedActivities?.[index];
    setSelectedAppliedActivity(activity || null);
  };

  const { logAvailability } = logAvailabilityController({
    activityApplication: selectedAppliedActivity
      ? selectedAppliedActivity.userApplication
      : undefined,
  });

  const { data: activitiesTeamToLog, isLoading: isLoadingActivitiesTeamToLog } =
    useQuery<PaginatedResponse<ITeam[]>>(`team`, {
      key: `activities-team-to-log-${appliedActivities.map((a) => a._id)}`,
      queryOptions: { enabled: appliedActivities.length > 0 },
      requestOptions: {
        params: {
          filter: JSON.stringify({
            activity: { $in: appliedActivities.map((a) => a._id) },
            members: { $in: [selectedUserProfile?._id] },
          }),
        },
      },
    });

  const team = useMemo(() => {
    if (isLoadingActivitiesTeamToLog) return;
    return activitiesTeamToLog?.data?.find(
      (t) => t.activity === selectedAppliedActivity?._id,
    );
  }, [
    activitiesTeamToLog,
    selectedAppliedActivity,
    isLoadingActivitiesTeamToLog,
  ]);

  const logLabel = activityType
    ? LogStyleMapByType.get(activityType).getTitle(appliedActivities.length)
    : '';

  const title = logAvailability.message || logLabel;

  const resetTeam = () => {
    // setTeamId(undefined);
  };

  const handleLogAction = () => {
    if (!selectedAppliedActivity) return;

    const applicationId = selectedAppliedActivity.userApplication?._id || '';

    const route = PAGES.LogActivity.replace(':id', applicationId);
    navigate(route);
  };

  const nextActivityIndex = (index: number) => {
    setActivityIndex(index + 1);
    selectAppliedActivityByIndex(index + 1);
  };

  const previousActivityIndex = (index: number) => {
    setActivityIndex(index - 1);
    selectAppliedActivityByIndex(index - 1);
  };

  return {
    handleLogAction,
    selectedAppliedActivity,
    selectAppliedActivity,
    activityDefinition,
    activityType,
    logAvailability,
    title,
    team,
    nextActivityIndex,
    previousActivityIndex,
    activityIndex,
    resetTeam,
    selectedUserProfile,
    _appliedActivities,
    isLoadingActivitiesTeamToLog,
  };
};
