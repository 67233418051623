import { isSameDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import {
  formatDateWeekday,
  formatTimeUtil,
  getDateMonthName,
} from 'ui/utils/formatDates';

import { BookingDateSelectCardProps } from '~/components/BookingDateSelectCard/bookingDateSelectCard.types';
import {
  TransformActivitiesToBookingDateCardParam,
  UseCardBookingSelectionEventControllerParams,
} from '~/components/CardBookingSelection/CardBookingSelectionEvent/cardBookingSelectionEvent.types';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';
import { redirectSliceActions } from '~/store/slices/redirect';
import { IActivity } from '~/types/interfaces/activity';
import { App } from '~/types/interfaces/app';

export const transformActivityList = (
  activities: Partial<IActivity>[],
  hideCardSelectIcon?: boolean,
  appsInfosById?: Record<string, App>,
  teamEventSize?: number,
): BookingDateSelectCardProps[] => {
  const castedActivities =
    activities as TransformActivitiesToBookingDateCardParam[];

  return castedActivities.map(
    ({
      _id,
      startDate,
      endDate,
      address,
      activityDefinitionSubDocument,
      isVolunteerNumberLimited,
      attendeesNumber,
      bookingsNumber,
      volunteerNumber,
      teamsNumber,
      isOnline,
      meetingLink,
    }) => {
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);
      const isTeamEvent =
        activityDefinitionSubDocument.eventApplicationType ===
        EEventApplicationType.Team;

      let spotsRemaining = isTeamEvent ? teamsNumber : volunteerNumber;

      const currentTeamsNumber = teamsNumber || 0;
      const currentBookingsNumber = bookingsNumber || 0;
      const currentAttendeesNumber = attendeesNumber || 0;
      const currentVolunteerNumber = volunteerNumber || 0;

      if (isTeamEvent) {
        spotsRemaining = currentTeamsNumber - currentBookingsNumber;
      } else {
        spotsRemaining =
          currentVolunteerNumber -
          (currentBookingsNumber - currentAttendeesNumber);
      }

      const bookingDateSelectDetails = [];

      if (startDate && endDate) {
        if (isSameDay(parsedStartDate, parsedEndDate)) {
          bookingDateSelectDetails.push({
            text: `${formatDateWeekday(parsedStartDate)}`,
            iconName: 'calendar',
          });
        } else {
          bookingDateSelectDetails.push({
            text: `${formatDateWeekday(parsedStartDate)}`,
            iconName: 'calendar',
          });
          bookingDateSelectDetails.push({
            text: `${formatDateWeekday(parsedEndDate)}`,
            iconName: 'calendar',
          });
        }
      }

      if (startDate && endDate) {
        bookingDateSelectDetails.push({
          iconName: 'clock',
          text: `${formatTimeUtil(parsedStartDate)} - ${formatTimeUtil(
            parsedEndDate,
          )}`,
        });
      }

      if (address?.street) {
        bookingDateSelectDetails.push({
          iconName: 'map-pin',
          text: address?.street,
        });
      }

      if (
        (activityDefinitionSubDocument?.locationOption ===
          LocationOptionsEnum.FromHome &&
          isOnline) ||
        meetingLink
      ) {
        bookingDateSelectDetails.push({
          iconName: 'wifi',
          text: 'Online',
        });
      }

      if (teamEventSize) {
        bookingDateSelectDetails.push({
          iconName: 'users',
          text: `${teamEventSize} team members`,
        });
      }

      return {
        activityId: _id,
        day: startDate
          ? String(parsedStartDate.getDate()).padStart(2, '0')
          : undefined,
        month: startDate
          ? getDateMonthName(parsedStartDate, 'short')
          : undefined,
        hideCardSelectIcon,
        bookingDateSelectDetails,
        activityTitle: activityDefinitionSubDocument?.title,
        appName: appsInfosById
          ? appsInfosById[activityDefinitionSubDocument?.app?.toString()]?.name
          : '',
        organizationName:
          activityDefinitionSubDocument?.organizationSubDocument.name,
        organizationLogo:
          activityDefinitionSubDocument.organizationSubDocument.logo,
        targetAmount: activityDefinitionSubDocument?.targetAmount,
        measurementUnitPluralLabel:
          activityDefinitionSubDocument.measurementUnitPluralLabel,
        activityDefinitionId: activityDefinitionSubDocument?._id,
        spotsRemaining,
        isVolunteerNumberLimited,
      };
    },
  );
};

export const useCardBookingSelectionEventController = ({
  activities,
  hideCardSelectIcon,
  bookedActivityId,
  isTeamEvent,
  onSelectTeam,
  isGuestPage,
  applicationId,
  externalApplyLink,
}: UseCardBookingSelectionEventControllerParams) => {
  const { goToRoute, replaceRoute } = useRouter();
  const { selectedEcosystem } = useAppSelector((state) => state.ecosystem);
  const { selectedUserProfile, availableAppsFromPermissionGroupsById } =
    useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [selectedActivityId, setSelectedActivityId] = useState<
    string | undefined
  >(undefined);

  const { data: teamsData, isLoading: isLoadingTeams } = useQuery({
    queryKey: [
      TeamService.URL,
      selectedUserProfile?._id,
      {
        filter: JSON.stringify({
          ecosystem: selectedEcosystem?._id,
        }),
        // TODO: Add projection params when the endpoint accepts it
        // projection: JSON.stringify({
        //   name: 1,
        // }),
        // This endpoint is paginated, but we want to get all the data at once
        // NOTE: Pagination is not implemented yet
        // limit: 1000,
      },
    ],
    queryFn: ({ queryKey }) => {
      const [, userProfileId, params] = queryKey as any;

      return TeamService.me(userProfileId, params);
    },
    enabled:
      isTeamEvent && !!selectedUserProfile?._id && !!selectedEcosystem?._id,
  });

  const teamsAvailableToApply = useMemo(
    () =>
      teamsData?.data.data.filter(
        (team) => selectedUserProfile?._id === team.leader,
      ),
    [teamsData?.data.data, selectedUserProfile?._id],
  );

  const handleSelectActivity = (activityId: string) => {
    setSelectedActivityId((prevState) => {
      return activityId === prevState ? undefined : activityId;
    });
  };

  const handleSelectTeam = (teamId: string) => {
    onSelectTeam(teamId);
    setSelectedTeamId((prevState) => {
      return teamId === prevState ? '' : teamId;
    });
  };

  const handlePressPrimaryAction = () => {
    const eventId = selectedActivityId || activities[0]._id;

    if (!eventId) return;

    if (isGuestPage) {
      dispatch(
        redirectSliceActions.addActivityDetailsRedirect({
          id: activities[0].activityDefinitionSubDocument?._id,
        }),
      );
      replaceRoute(PAGES.Root);
      return;
    }

    if (!!bookedActivityId) {
      goToRoute(`/my-wallet/log-activity/${applicationId}`);
      return;
    }

    if (isTeamEvent) {
      const teamEventApplicationRoute = PAGES.TeamEventApplication.replace(
        ':eventId',
        eventId,
      ).replace(':teamId', selectedTeamId);

      goToRoute(teamEventApplicationRoute);

      return;
    }

    if (externalApplyLink) {
      goToRoute(PAGES.ExternalApplyLinkRedirect.replace(':id', eventId), {
        state: {
          externalApplyLink,
          activityAppId: activities.find((activity) => activity._id === eventId)
            ?.activityDefinitionSubDocument?.app as string,
        },
      });

      return;
    }

    const eventApplicationRoute = PAGES.EventApplication.replace(
      ':eventId',
      eventId,
    );
    goToRoute(eventApplicationRoute);

    return;
  };

  const bookingDateCards = useMemo(() => {
    return transformActivityList(activities, hideCardSelectIcon);
  }, [activities, hideCardSelectIcon]);

  return {
    selectedActivityId,
    handleSelectActivity,
    handlePressPrimaryAction,
    bookingDateCards,
    teamsData,
    isLoadingTeams,
    handleSelectTeam,
    teamsAvailableToApply,
  };
};
