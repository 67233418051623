import {
  Box,
  HStack,
  ScrollView,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  VStack,
} from 'native-base';
import SwipeableViews from 'react-swipeable-views';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';
import { ActivitySemanticType } from 'ui/types/activities';

import { ActivityPanel } from '~/pages/Authenticated/v2/ActivityView/components/ActivityPanel';
import { useActivityLogPanelController } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/controller';
import { ActivityLogPanelProps } from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/ActivityLogPanel/types';
import ActionApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/ActionApplicationCard';
import EventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/EventApplicationCard';
import OngoingApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/OngoingApplicationCard';
import TeamEventApplicationCard from '~/pages/Authenticated/v2/ActivityView/views/ActivityLog/components/TeamEventApplicationCard';
import { IActivityDefinition } from '~/types/interfaces/activity';

import { TeamInfo } from '../components/TeamInfo';

export const ActivityLogPanel: React.FC<ActivityLogPanelProps> = (
  props: ActivityLogPanelProps,
) => {
  const { activitiesTotal, appliedActivities, onGoBackPress, teams } = props;

  const {
    handleLogAction,
    selectAppliedActivity,
    selectedAppliedActivity,
    activityDefinition,
    logAvailability,
    activityType,
    title,
    nextActivityIndex,
    previousActivityIndex,
    activityIndex,
    team,
    resetTeam,
    _appliedActivities,
    isLoadingActivitiesTeamToLog,
  } = useActivityLogPanelController(props);

  // Remove o time que o organizador faz parte e filtra por atividade selecionada
  const _teams =
    teams
      ?.filter(
        (t) =>
          !t.members.includes(
            selectedAppliedActivity?.userApplication?.userProfile || '',
          ),
      )
      .filter((t) => t.activity === selectedAppliedActivity?._id) || [];

  return (
    <ActivityPanel
      title={title}
      headerBackground={colors.violet[200]}
      titleColor={colors.violet[900]}
    >
      <VStack space={3}>
        <Box maxH={'420px'}>
          {activityType === ActivitySemanticType.TeamEvent ? (
            <SwipeableViews index={activityIndex}>
              {_appliedActivities?.map((activity, index) => (
                <TeamEventApplicationCard
                  key={activity._id}
                  activity={activity}
                  showChevronLeft={
                    _appliedActivities?.length > 1 &&
                    _appliedActivities?.length - 1 <= activityIndex
                  }
                  showChevronRight={
                    _appliedActivities?.length > 1 &&
                    _appliedActivities?.length - 1 > activityIndex
                  }
                  showCheckCircle={false}
                  onNext={() => nextActivityIndex(index)}
                  onPrevious={() => previousActivityIndex(index)}
                />
              ))}
            </SwipeableViews>
          ) : (
            <ScrollView>
              <VStack w="full">
                {appliedActivities.map((activity) => {
                  switch (activityType) {
                    case ActivitySemanticType.Action:
                      return (
                        <ActionApplicationCard
                          key={activity._id}
                          activity={activity}
                          activityDefinition={
                            activityDefinition as IActivityDefinition
                          }
                        />
                      );
                    case ActivitySemanticType.Ongoing:
                      return (
                        <OngoingApplicationCard
                          key={activity._id}
                          activity={activity}
                          isSelected={
                            selectedAppliedActivity?._id === activity._id
                          }
                          onPress={selectAppliedActivity}
                        />
                      );

                    default:
                      return (
                        <EventApplicationCard
                          key={activity._id}
                          activity={activity}
                          onPress={selectAppliedActivity}
                          isSelected={
                            selectedAppliedActivity?._id === activity._id
                          }
                        />
                      );
                  }
                })}
              </VStack>
            </ScrollView>
          )}
        </Box>

        {appliedActivities.length < activitiesTotal && (
          <Stack
            w="full"
            alignItems={'center'}
            borderRadius={'12px'}
            backgroundColor={colors.gray[100]}
          >
            <Button
              w="full"
              variant="ghost"
              colorScheme="darkText"
              onPress={onGoBackPress}
              rightIconName="chevron-right"
              rightIconColor={colors.singletons.darkText}
            >
              <Text color="singleton.black" fontWeight={500} fontSize="sm">
                Book more dates
              </Text>
            </Button>
          </Stack>
        )}

        {activityType !== ActivitySemanticType.TeamEvent ? (
          <HStack mt={6}>
            <Tooltip
              label={logAvailability.message}
              display={!logAvailability.message ? 'none' : 'unset'}
            >
              <Button
                onPress={handleLogAction}
                isDisabled={!logAvailability.isAvailable}
                bg={'singletons.black'}
                h={'2.5rem'}
                color={'white'}
              >
                <Text fontWeight={'500'} color={'inherit'} fontSize={'md'}>
                  Log activity
                </Text>
              </Button>
            </Tooltip>
            <Box></Box>
          </HStack>
        ) : null}

        {activityType === ActivitySemanticType.TeamEvent ? (
          <>
            {isLoadingActivitiesTeamToLog ? (
              <>
                <Skeleton width="25%" height={4} />
                <Skeleton height="140px" borderRadius={12} />
              </>
            ) : (
              <>
                {team || _teams.length ? <TeamInfo.Header /> : null}
                {team ? (
                  <TeamInfo.Members.Info team={team}>
                    <TeamInfo.Members.Footer
                      logAvailability={logAvailability}
                      handleLogAction={handleLogAction}
                      showLogActivity={true}
                      canCancelApplication={
                        team.leader ===
                        selectedAppliedActivity?.userApplication?.userProfile
                      }
                      canLeaveTeam={true}
                      team={team}
                      resetTeam={resetTeam}
                    />
                  </TeamInfo.Members.Info>
                ) : null}

                {_teams.map((team) => (
                  <TeamInfo.Members.Info key={team._id} team={team}>
                    <TeamInfo.Members.Footer
                      logAvailability={logAvailability}
                      team={team}
                      canCancelApplication={
                        team.leader ===
                        selectedAppliedActivity?.userApplication?.userProfile
                      }
                      canLeaveTeam={
                        team.leader !==
                        selectedAppliedActivity?.userApplication?.userProfile
                      }
                    />
                  </TeamInfo.Members.Info>
                ))}
              </>
            )}

            <Button
              backgroundColor="gray.100"
              _text={{ color: colors.singletons.black, fontWeight: 500 }}
              leftIconName="plus"
              leftIconColor={colors.singletons.black}
              leftIconSize={16}
              onPress={onGoBackPress}
            >
              Add team
            </Button>
          </>
        ) : null}
      </VStack>
    </ActivityPanel>
  );
};

export default ActivityLogPanel;
