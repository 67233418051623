import { useState } from 'react';

import { useTeamEventStore } from '~/zustand/store/TeamEvent';

import { ActivityTeamEventCounterProps } from './types';

export const useActivityTeamEventCounterController = ({
  teamMaxSize,
  teamMinSize,
}: ActivityTeamEventCounterProps) => {
  const { teamEventSize, setTeamEventSize } = useTeamEventStore();
  const [isTeamEventSizeModified, setIsTeamEventSizeModified] = useState(false);

  const handleIncrement = () => {
    if (!isTeamEventSizeModified) {
      setTeamEventSize(teamMinSize);
      setIsTeamEventSizeModified(true);
      return;
    }

    if (teamEventSize < teamMaxSize) {
      setTeamEventSize(teamEventSize + 1);
      setIsTeamEventSizeModified(true);
    }
  };

  const handleDecrement = () => {
    if (teamEventSize > teamMinSize) {
      setTeamEventSize(teamEventSize - 1);
      setIsTeamEventSizeModified(true);
    }
  };

  return {
    teamEventSize,
    handleIncrement,
    handleDecrement,
    isTeamEventSizeModified,
  };
};
