import { api } from '~/services/api';
import { EmailPreferencesDTO } from '~/types/dtos';

export class UserProfileService {
  static MODULE_URL = '/user-profile';

  static async getProfilesByUser(userId: string) {
    return await api.get(`${this.MODULE_URL}/user/${userId}`);
  }

  static async userProfileAutoCompleteSearch(
    search: string,
    ecosystemId: string,
  ) {
    return await api.get(`${this.MODULE_URL}/autocomplete`, {
      params: {
        text: search,
        ecosystem: ecosystemId,
      },
    });
  }

  static async updateUserProfile(
    userProfileId: string,
    fields: {
      profilePicture: string;
      externalId?: string;
      organizationDepartment?: string | null;
    },
  ) {
    return await api.put(`${this.MODULE_URL}/${userProfileId}`, {
      ...fields,
    });
  }

  static async updateUserExternalId(
    userProfileId: string,
    data: { externalId: string },
  ) {
    return await api.put(`${this.MODULE_URL}/${userProfileId}`, {
      ...data,
    });
  }

  static async updateUserProfileEmailPreferences({
    userProfileId,
    emailPreferences,
  }: {
    userProfileId: string;
    emailPreferences: EmailPreferencesDTO;
  }): Promise<void> {
    return await api.put(`${this.MODULE_URL}/${userProfileId}`, {
      emailPreferences,
    });
  }
}
