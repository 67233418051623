import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from 'native-base';
import { useMemo, useRef } from 'react';
import Icon from 'ui/components/Icon';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { colors } from 'ui/theme/colors';

import { TeamEventModal } from '~/components/TeamEventModal';
import { useAppSelector } from '~/hooks/useAppSelector';

import { TeamInfoMembersProps } from './types';

export const TeamMembersInfo = ({ children, team }: TeamInfoMembersProps) => {
  const totalMembers =
    (team.members?.length || 0) + (team?.offPlatformMembers?.length || 0);

  const teamEventModalRef = useRef<IModalRefProps | null>(null);

  const { selectedUserProfile } = useAppSelector((state) => state.auth);
  const { selectedEcosystem } = useAppSelector((state) => state.ecosystem);

  const memberPics = useMemo(() => {
    const pics = [];
    for (const member of team.membersSubDocument) {
      pics.push({ _id: member._id, picture: member?.profilePicture });
    }
    for (const member of team.offPlatformMembers || []) {
      pics.push({ _id: member._id, picture: '' });
    }
    return pics;
  }, [team]);

  const canEditTeam = useMemo(() => {
    return team.leader === selectedUserProfile?._id;
  }, [team, selectedUserProfile]);

  return (
    <>
      <TeamEventModal
        teamEventModalRef={teamEventModalRef}
        teamId={team._id}
        selectedEcosystem={selectedEcosystem}
      />
      <Stack
        space={3}
        borderWidth={1}
        borderColor={colors.gray[200]}
        borderRadius={12}
        padding={3}
        bgColor={colors.gray[50]}
      >
        <Stack>
          <HStack justifyContent="space-between" alignItems="center">
            <Tooltip label={team.name}>
              <Text numberOfLines={1} maxW="150px">
                {team.name}
              </Text>
            </Tooltip>
            {canEditTeam ? (
              <IconButton
                alignItems="center"
                borderRadius={8}
                borderWidth={1}
                borderColor={colors.gray[200]}
                height={'34px'}
                width={'34px'}
                icon={<Icon icon="edit" size={16} color={colors.gray[600]} />}
                onPress={() => teamEventModalRef.current?.open()}
              />
            ) : null}
          </HStack>
          <HStack space={8}>
            <Stack space={1}>
              <Text fontSize="xs" fontWeight={500} color="gray.600">
                Booker
              </Text>
              <HStack space={2} alignItems="center">
                <Avatar
                  height={8}
                  width={8}
                  source={{ uri: team.leaderSubDocument.profilePicture }}
                >
                  <Icon icon="user" size={24} color={colors.white} />
                </Avatar>
                <Text color={colors.singletons.darkText}>
                  {team.leaderSubDocument.userSummary.name}
                </Text>
              </HStack>
            </Stack>
            <Stack space={1} alignItems="flex-start">
              <Text fontSize="xs" fontWeight={500} color="gray.600">
                {totalMembers > 0 ? `${totalMembers} members` : 'No members'}
              </Text>

              {/*TODO: Transformar isso em um componente */}
              <Box ml={3} flexDirection="row" alignItems="center">
                {memberPics.slice(0, 4).map((m) => (
                  <Avatar
                    key={m._id}
                    height={8}
                    width={8}
                    source={{ uri: m.picture }}
                    ml={-3}
                  >
                    <Icon icon="user" size={24} color={colors.white} />
                  </Avatar>
                ))}

                {memberPics.length > 4 && (
                  <Avatar
                    height={8}
                    width={8}
                    bg="gray.400"
                    ml={-3}
                    alignItems="center"
                    justifyContent="center"
                    _text={{
                      fontSize: 'sm',
                      color: 'white',
                    }}
                  >
                    <HStack>
                      <Text fontSize="md" color="white" fontWeight={500}>
                        +{memberPics.length - 4}
                      </Text>
                    </HStack>
                  </Avatar>
                )}
              </Box>
            </Stack>
          </HStack>
        </Stack>

        {children || null}
      </Stack>
    </>
  );
};
