import { DOIT_VOLUNTEER_URL } from '~/config';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { sanitizeUrl } from '~/utils/transformURL';

export const makeActivityLink = (activityDefinitionId: string) => {
  const url = `${DOIT_VOLUNTEER_URL}my-wallet/activities/${activityDefinitionId}`;
  return sanitizeUrl(url);
};

export const mergeAppliedActivities = (
  array1: IUserActivity[] = [],
  array2: IUserActivity[] = [],
) => {
  const appliedActivitiesMerged = [...array1, ...array2].reduce((acc, item) => {
    acc.set(item._id, item);
    return acc;
  }, new Map());

  return appliedActivitiesMerged;
};
